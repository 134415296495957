import React, { useRef, useState } from "react";
import { Project } from "..";
import { projectList } from "./projectList";
import { SecTitle } from "..";
import "./projects.scss";

const Projects = () => {
  const ref = useRef();
  const [isActive, setIsActive] = useState(false);

  const activeHandler = (e) => {
    console.log(e.target.dataset.id);
    setIsActive(true);
  };

  return (
    <div className="projects">
      <div className="projContainer">
        <SecTitle title={`My Projects (${projectList?.length})`} />
        {/* <div className='projCategories'>
                    <ul className='categList'>
                        <li ref={ref} data-id= "1" onClick={(e) => activeHandler(e)}  className={isActive ? 'categListItem active' : 'categListItem'}>All</li>
                        <li ref={ref} data-id= "2" onClick={(e) => activeHandler(e)}  className={isActive ? 'categListItem active' : 'categListItem'}>eCommerce</li>
                        <li ref={ref} data-id= "3" onClick={(e) => activeHandler(e)}  className={isActive ? 'categListItem active' : 'categListItem'}>Business</li>
                        <li ref={ref} data-id= "4" onClick={(e) => activeHandler(e)}  className={isActive ? 'categListItem active' : 'categListItem'}>Nonprofit</li>
                        <li ref={ref} data-id= "5" onClick={(e) => activeHandler(e)}  className={isActive ? 'categListItem active' : 'categListItem'}>Informational</li>
                        <li ref={ref} data-id= "6" onClick={(e) => activeHandler(e)}  className={isActive ? 'categListItem active' : 'categListItem'}>Portfolio</li>
                    </ul>
                </div> */}
        <div className="allProjects">
          {projectList?.map((item, key) => (
            <Project
              key={item.id}
              id={item.id}
              img={item.img}
              link={item.link}
              gitHub={item.gitHub}
              originalLink={item.originalLink}
              skillsList={item?.skillsList}
              commingSoon={item?.commingSoon}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Projects;
