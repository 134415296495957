import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import React from 'react';
import './project.scss';

const Project = ({id, img, link, gitHub, originalLink, skillsList, commingSoon}) => {
    return (
        <div className='project'>
            <div className='projLeft'>
                {skillsList?.map((skill, index) => (
                    <Tooltip arrow placement="right" title={skill.skillname}>
                        <img key={skill.skillname} className='icon' src={skill.skillIcon} alt={skill.skillname} />
                    </Tooltip>
                ))}
            </div>
            <div className='projRight'>
                <div className='projTop'>
                    <a href={link} target='_blank' rel="noreferrer">
                        <img className='progImg' src={process.env.PUBLIC_URL + `${img}`} alt=''/>
                    </a>
                    <span className='projTopName'>{id}</span>
                    {commingSoon && <span className='commingSoon'>Under Development</span>}
                </div>
                <div className='projBottom'>
                    <a href={gitHub} target='_blank' rel="noreferrer" title={gitHub}>
                        <Tooltip arrow placement="bottom" title='Github'>
                            <FontAwesomeIcon className='icon' icon={faGithub} />
                        </Tooltip>
                    </a>
                    <a href={originalLink} target='_blank' rel="noreferrer" title={originalLink}>
                        <Tooltip arrow placement="bottom" title='Original link'>
                            <FontAwesomeIcon className='icon' icon={faCopy} />
                        </Tooltip>
                    </a>
                    <a href={link} target='_top' rel="noreferrer" title={link}>
                        <Tooltip arrow placement="bottom" title='Link'>
                            <FontAwesomeIcon className='icon' icon={faLink} />
                        </Tooltip>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Project;
