import React from 'react';
import './footer.scss';

const Footer = () => {
    return (
        <div className='footer'>
            <div className='footerTxt'>Copyright &copy; 2023 <span className='name'>Ahmed Khaled AbdAllah</span> Portfolio. All Rights Reserved</div>
        </div>
    )
}

export default Footer;
