import Recoiljs from "./icons/recoiljs.png";
import Tailwind from "./icons/tailwind.png";
import NEXTJS from "./icons/next.png";
import MUI from "./icons/logoMUI.png";
import RTK from "./icons/redux.svg";
import React from "./icons/react.svg";
import JS from "./icons/javascript.svg";
import SASS from "./icons/sass.svg";
import JQuery from "./icons/jquery.svg";
import BootStrap from "./icons/bootstrap5.svg";
import CSS from "./icons/css3.svg";
import HTML from "./icons/html5.svg";

const TailwindObj = { skillname: "Tailwind CSS", skillIcon: Tailwind };
const RecoiljsObj = { skillname: "Recoiljs", skillIcon: Recoiljs };
const NEXTJSObj = { skillname: "NEXTJS", skillIcon: NEXTJS };
const CSS3Obj = { skillname: "CSS3", skillIcon: CSS };
const HTML5Obj = { skillname: "HTML5", skillIcon: HTML };
const MUIObj = { skillname: "Material UI", skillIcon: MUI };
const JSObj = { skillname: "JavaScript", skillIcon: JS };
const SASSObj = { skillname: "SASS", skillIcon: SASS };
const ReactJsObj = { skillname: "ReactJs", skillIcon: React };
const RTKObj = { skillname: "Redux Tool Kit", skillIcon: RTK };
const JQueryObj = { skillname: "JQuery", skillIcon: JQuery };
const BootStrapObj = { skillname: "BootStrap4", skillIcon: BootStrap };

export const projectList = [
  // {
  //     id: 'OpenTable',
  //     img: "/imgs/opentable.png",
  //     link: "/",
  //     originalLink: "https://www.opentable.com",
  //     gitHub: "https://github.com/Ahmd-Khaled/opentable1",
  //     skillsList: [TailwindObj, NEXTJSObj, CSS3Obj, HTML5Obj],
  //     commingSoon: true
  // },
  // {
  //     id: 'ShopPay',
  //     img: "/imgs/shoppay.png",
  //     link: "/",
  //     originalLink: "/",
  //     gitHub: "/",
  //     skillsList: [ NEXTJSObj, JSObj, CSS3Obj, HTML5Obj],
  //     commingSoon: true
  // },
  {
    id: "yumGott Dashboard",
    img: "/imgs/yumgott.png",
    link: "https://yumgott-dashboard.vercel.app/",
    originalLink: "https://yumgott-dashboard.vercel.app/",
    gitHub: "https://github.com/Ahmd-Khaled/",
    skillsList: [NEXTJSObj, JSObj, SASSObj, HTML5Obj],
    commingSoon: true,
  },
  {
    id: "Khotwa",
    img: "/imgs/khotwa.png",
    link: "https://www.khotwa-map.com/",
    originalLink: "https://www.khotwa-map.com/",
    gitHub: "https://github.com/Ahmd-Khaled/",
    skillsList: [NEXTJSObj, JSObj, SASSObj, HTML5Obj],
  },
  {
    id: "Gold Era Dashboard",
    img: "/imgs/goldera.png",
    link: "https://golderadashboard.vercel.app/",
    originalLink: "https://golderadashboard.vercel.app/",
    gitHub: "https://github.com/Ahmd-Khaled/",
    skillsList: [NEXTJSObj, JSObj, SASSObj, HTML5Obj],
  },
  {
    id: "Gold Era Platform",
    img: "/imgs/golderaplatform.png",
    link: "https://golderaplatform.vercel.app",
    originalLink: "https://golderaplatform.vercel.app",
    gitHub: "https://github.com/Ahmd-Khaled/",
    skillsList: [ReactJsObj, JSObj, SASSObj, HTML5Obj],
  },
  {
    id: "Seha",
    img: "/imgs/seha2.png",
    link: "https://saha.ws/",
    originalLink: "https://saha.ws/",
    gitHub: "https://github.com/Ahmd-Khaled/",
    skillsList: [NEXTJSObj, JSObj, SASSObj, HTML5Obj],
  },
  {
    id: "HATRIX",
    img: "/imgs/hatrix.png",
    link: "https://hatrix.vercel.app/",
    originalLink: "https://hatrix.vercel.app/",
    gitHub: "https://github.com/Ahmd-Khaled/",
    skillsList: [NEXTJSObj, JSObj, SASSObj, HTML5Obj],
  },
  {
    id: "D2020",
    img: "/imgs/d2020-website.png",
    link: "https://d2020web.vercel.app/",
    originalLink: "/",
    gitHub: "https://github.com/Ahmd-Khaled/",
    skillsList: [NEXTJSObj, JSObj, SASSObj, HTML5Obj],
  },
  {
    id: "Contracts Dashboard",
    img: "/imgs/contracts.png",
    link: "https://d2020-contracts-dashboard.vercel.app/",
    originalLink: "/",
    gitHub: "https://github.com/Ahmd-Khaled/",
    skillsList: [RTKObj, ReactJsObj, JSObj, SASSObj, HTML5Obj],
  },
  {
    id: "Nestrettocoffee - Ecommerce",
    img: "/imgs/nesco.png",
    link: "https://nesco-react.vercel.app/",
    // link: "https://nestrettocoffee.com/",
    originalLink: "/",
    gitHub: "https://github.com/Ahmd-Khaled/",
    skillsList: [RTKObj, ReactJsObj, JSObj, SASSObj, HTML5Obj],
  },
  {
    id: "Serih Azoz",
    img: "/imgs/serih-website.png",
    link: "https://serih-menu.net/",
    // link: "https://serih.vercel.app/",
    originalLink: "https://serih-menu.net/",
    gitHub: "https://github.com/Ahmd-Khaled/",
    skillsList: [NEXTJSObj, JSObj, SASSObj, HTML5Obj],
  },
  {
    id: "Kayan Egypt",
    img: "/imgs/kayan.png",
    link: "https://kayan-egypt.vercel.app/",
    originalLink: "https://kayan-egypt.vercel.app/",
    gitHub: "https://github.com/Ahmd-Khaled/",
    skillsList: [NEXTJSObj, JSObj, SASSObj, HTML5Obj],
  },
  {
    id: "Fast Cart",
    img: "/imgs/fast-cart.png",
    link: "https://fastcart.vercel.app/ar",
    originalLink: "https://fastcart.vercel.app/ar",
    gitHub: "https://github.com/Ahmd-Khaled/",
    skillsList: [NEXTJSObj, JSObj, SASSObj, HTML5Obj],
  },
  {
    id: "Mahercp",
    img: "/imgs/mahercp.png",
    link: "https://mbn2-bice.vercel.app/",
    originalLink: "/",
    gitHub: "https://mbn2-bice.vercel.app/",
    skillsList: [NEXTJSObj, JSObj, SASSObj, HTML5Obj],
  },
  {
    id: "Mahercp Job Request",
    img: "/imgs/mahercp-website.png",
    link: "https://mbn2.vercel.app/cvs",
    originalLink: "/",
    gitHub: "https://github.com/Ahmd-Khaled/",
    skillsList: [NEXTJSObj, JSObj, SASSObj, HTML5Obj],
  },
  {
    id: "Easyelivator",
    img: "/imgs/Easyelivator-website.png",
    link: "https://www.easyelivator.net/",
    originalLink: "/",
    gitHub: "https://github.com/Ahmd-Khaled/",
    skillsList: [ReactJsObj, JSObj, SASSObj, HTML5Obj],
  },
  {
    id: "Salla - Ecommerce",
    img: "/imgs/salla.png",
    link: "https://salla-lilac.vercel.app/",
    originalLink: "/",
    gitHub: "https://github.com/Ahmd-Khaled/",
    skillsList: [RTKObj, NEXTJSObj, JSObj, SASSObj, HTML5Obj],
  },
  {
    id: "learnonline",
    img: "/imgs/learnonline.png",
    link: "/",
    originalLink: "/",
    gitHub: "https://github.com/Ahmd-Khaled/",
    skillsList: [NEXTJSObj, JSObj, SASSObj, HTML5Obj],
    // commingSoon: true
  },
  {
    id: "cleanhome",
    img: "/imgs/cleanhome.png",
    link: "https://homeservice1-nu.vercel.app",
    // link: "https://cleanhome.ahmedkhald.com",
    originalLink: "/",
    gitHub: "https://github.com/Ahmd-Khaled/",
    skillsList: [NEXTJSObj, JSObj, SASSObj, HTML5Obj],
  },
  {
    id: "spotify",
    img: "/imgs/spotify.png",
    link: "https://spotify-kappa-snowy.vercel.app/",
    originalLink: "https://open.spotify.com/",
    gitHub: "https://github.com/Ahmd-Khaled/spotify",
    skillsList: [NEXTJSObj, JSObj, CSS3Obj, HTML5Obj],
    // commingSoon: true
  },
  {
    id: "twitter",
    img: "/imgs/twitter.png",
    link: "https://twitter-v4-8v43wf8ys-ahmd-khaled.vercel.app/",
    originalLink: "https://www.twitter.com/",
    gitHub: "https://github.com/Ahmd-Khaled/twitter-v4",
    skillsList: [TailwindObj, RecoiljsObj, NEXTJSObj, CSS3Obj, HTML5Obj],
  },
  {
    id: "kibsons dashboard",
    img: "/imgs/kibsonsadmin.png",
    link: "https://kibsonsadmin.vercel.app/",
    // link: "https://kibadmin.ahmedkhald.com/",
    originalLink: "https://www.kibsons.com/",
    gitHub: "https://github.com/Ahmd-Khaled/kibsonsadmin",
    skillsList: [MUIObj, ReactJsObj, JSObj, SASSObj, CSS3Obj, HTML5Obj],
  },
  {
    id: "Kibsons",
    img: "/imgs/Kibsons.png",
    link: "https://kibsons-three.vercel.app/",
    // link: "https://kibsons.ahmedkhald.com/",
    originalLink: "https://www.kibsons.com/",
    gitHub: "https://github.com/Ahmd-Khaled/Kibsons",
    skillsList: [RTKObj, ReactJsObj, JSObj, CSS3Obj, HTML5Obj],
  },
  {
    id: "metro",
    img: "/imgs/metro.png",
    link: "https://metro-seven.vercel.app",
    // link: "https://metro.ahmedkhald.com",
    originalLink: "https://www.metro-markets.com/",
    gitHub: "https://github.com/Ahmd-Khaled/metro",
    skillsList: [ReactJsObj, JSObj, CSS3Obj, HTML5Obj],
  },
  {
    id: "crud app",
    img: "/imgs/crud2.png",
    link: "https://crud-amber.vercel.app/",
    // link: "https://crud.ahmedkhald.com",
    originalLink: "",
    gitHub: "https://github.com/Ahmd-Khaled/crud",
    skillsList: [ReactJsObj, JSObj, SASSObj, CSS3Obj, HTML5Obj],
  },
  {
    id: "portfolio",
    img: "/imgs/portfolio.png",
    link: "https://ahmedkhaledportfolio3.netlify.app/",
    originalLink: "/",
    gitHub: "",
    skillsList: [ReactJsObj, JSObj, SASSObj, CSS3Obj, HTML5Obj],
  },
  {
    id: "quiety",
    img: "/imgs/quiety.png",
    link: "https://quiety1.vercel.app/",
    // link: "https://quiety.ahmedkhald.com/",
    originalLink: "/",
    gitHub: "https://github.com/Ahmd-Khaled/quiety1",
    skillsList: [ReactJsObj, JSObj, CSS3Obj, HTML5Obj],
  },
  {
    id: "HA group",
    img: "/imgs/hassan.png",
    link: "https://hacg.vercel.app/",
    // link: "https://hacg.ahmedkhald.com",
    originalLink: "http://ha-cg.com/",
    gitHub: "https://github.com/Ahmd-Khaled/hacg",
    skillsList: [JSObj, CSS3Obj, HTML5Obj],
  },
  {
    id: "letsia",
    img: "/imgs/letsia.png",
    link: "https://letsia2.vercel.app/",
    // link: "https://letsia.ahmedkhald.com",
    originalLink: "/",
    gitHub: "https://github.com/Ahmd-Khaled/Letsia1",
    skillsList: [JSObj, CSS3Obj, HTML5Obj],
  },
  {
    id: "travel",
    img: "/imgs/travel.png",
    link: "https://travel1-nine.vercel.app",
    // link: "https://travel.ahmedkhald.com",
    originalLink: "https://www.figma.com/community/file/1045347165786104508",
    gitHub: "https://github.com/Ahmd-Khaled/Travel1",
    skillsList: [JSObj, CSS3Obj, HTML5Obj],
  },
  {
    id: "EFP",
    img: "/imgs/efp.png",
    link: "https://efp1.vercel.app/",
    // link: "https://efp.ahmedkhald.com",
    originalLink: "https://www.efp.org/",
    gitHub: "https://github.com/Ahmd-Khaled/efp1",
    skillsList: [JSObj, CSS3Obj, HTML5Obj],
  },
  {
    id: "aldewan",
    img: "/imgs/aldewan.png",
    link: "https://aldewan1.vercel.app",
    // link: "https://aldewan.ahmedkhald.com",
    originalLink: "/",
    gitHub: "https://github.com/Ahmd-Khaled/aldewan1",
    skillsList: [JSObj, CSS3Obj, HTML5Obj],
  },
  // {
  //     id: 'filmpire',
  //     img: "/imgs/filmpire.png",
  //     link: "https://filmpire.ahmedkhald.com",
  //     originalLink: "/",
  //     gitHub: "https://github.com/Ahmd-Khaled/filmpire_ahkhaled",
  //     skillsList: [MUIObj, RTKObj, ReactJsObj, JSObj, CSS3Obj, HTML5Obj],
  // },
  {
    id: "filmpire",
    img: "/imgs/filmpire.png",
    link: "https://filmpire-sooty.vercel.app/",
    // link: "https://filmpire.ahmedkhald.com",
    originalLink: "/",
    gitHub: "https://github.com/Ahmd-Khaled/filmpire",
    skillsList: [MUIObj, RTKObj, ReactJsObj, JSObj, CSS3Obj, HTML5Obj],
  },
  {
    id: "youtube clone",
    img: "/imgs/youtubeclone.png",
    link: "https://youtube.ahmedkhald.com",
    originalLink: "https://www.youtube.com/",
    gitHub: "/",
    skillsList: [ReactJsObj, JSObj, CSS3Obj, HTML5Obj],
  },
  {
    id: "scout",
    img: "/imgs/scout.png",
    link: "https://scout1-kappa.vercel.app/",
    // link: "https://scout.ahmedkhald.com",
    originalLink: "/",
    gitHub: "https://vercel.com/ahmd-khaled/scout1",
    skillsList: [JSObj, CSS3Obj, HTML5Obj],
  },
  {
    id: "splendorous",
    img: "/imgs/splendorous.png",
    link: "https://splendorous0.vercel.app",
    // link: "https://splendorous.ahmedkhald.com",
    originalLink: "/",
    gitHub: "https://github.com/Ahmd-Khaled/splendorous0",
    skillsList: [JSObj, CSS3Obj, HTML5Obj],
  },
  {
    id: "volunteer",
    img: "/imgs/volunteer.png",
    link: "https://volunteer1.vercel.app",
    // link: "https://volunteer.ahmedkhald.com",
    originalLink: "/",
    gitHub: "https://github.com/Ahmd-Khaled/volunteer1",
    skillsList: [JSObj, CSS3Obj, HTML5Obj],
  },
  {
    id: "co-append",
    img: "/imgs/co-append.png",
    link: "https://coappend.vercel.app",
    // link: "https://coappend.ahmedkhald.com",
    originalLink: "/",
    gitHub: "https://github.com/Ahmd-Khaled/coappend",
    skillsList: [JSObj, CSS3Obj, HTML5Obj],
  },
  {
    id: "bondi",
    img: "/imgs/bondi.png",
    link: "https://bondi1.vercel.app",
    // link: "https://bondi.ahmedkhald.com",
    originalLink: "/",
    gitHub: "https://github.com/Ahmd-Khaled/bondi1",
    skillsList: [JSObj, CSS3Obj, HTML5Obj],
  },
  {
    id: "eduport",
    img: "/imgs/eduport.png",
    link: "https://eduport1.vercel.app",
    // link: "https://eduport.ahmedkhald.com",
    originalLink: "/",
    gitHub: "https://github.com/Ahmd-Khaled/eduport1",
    skillsList: [JSObj, CSS3Obj, HTML5Obj],
  },
  // --------------------------
  {
    id: "mywork",
    img: "/imgs/mywork.png",
    link: "https://mywork.ahmedkhald.com",
    originalLink: "/",
    gitHub: "/",
    skillsList: [CSS3Obj, HTML5Obj],
  },
  {
    id: "kasper",
    img: "/imgs/kasper.png",
    link: "https://kasper-ashen.vercel.app/",
    // link: "https://kasper.ahmedkhald.com",
    originalLink: "/",
    gitHub: "https://github.com/Ahmd-Khaled/kasper",
    skillsList: [JQueryObj, BootStrapObj, JSObj, CSS3Obj, HTML5Obj],
  },
  {
    id: "leon",
    img: "/imgs/leon.png",
    link: "https://leon-alpha.vercel.app",
    // link: "https://leon.ahmedkhald.com",
    originalLink: "/",
    gitHub: "https://github.com/Ahmd-Khaled/leon",
    skillsList: [JQueryObj, BootStrapObj, JSObj, CSS3Obj, HTML5Obj],
  },
  {
    id: "special",
    img: "/imgs/special.png",
    link: "https://special-ten.vercel.app",
    // link: "https://special.ahmedkhald.com",
    originalLink: "/",
    gitHub: "https://github.com/Ahmd-Khaled/special",
    skillsList: [JQueryObj, BootStrapObj, JSObj, CSS3Obj, HTML5Obj],
  },
  {
    id: "elite",
    img: "/imgs/elite.png",
    link: "https://elite-hazel.vercel.app",
    // link: "https://elite.ahmedkhald.com",
    originalLink: "/",
    gitHub: "https://github.com/Ahmd-Khaled/elite",
    skillsList: [JQueryObj, BootStrapObj, JSObj, CSS3Obj, HTML5Obj],
  },
  {
    id: "axit",
    img: "/imgs/axit.png",
    link: "https://axit-roan.vercel.app",
    // link: "https://axit.ahmedkhald.com",
    originalLink: "/",
    gitHub: "https://github.com/Ahmd-Khaled/axit",
    skillsList: [JQueryObj, BootStrapObj, JSObj, CSS3Obj, HTML5Obj],
  },
  {
    id: "classic",
    img: "/imgs/classic.png",
    link: "https://classic-ten.vercel.app",
    originalLink: "/",
    gitHub: "https://github.com/Ahmd-Khaled/classic",
    skillsList: [JQueryObj, CSS3Obj, HTML5Obj],
  },
];

export const skillsList = [
  {
    name: "Nextjs",
    icon: NEXTJS,
  },
  {
    name: "Reactjs",
    icon: React,
  },
  {
    name: "Redux Toolkit",
    icon: RTK,
  },
  {
    name: "JavaScript",
    icon: JS,
  },

  {
    name: "Tailwind CSS",
    icon: Tailwind,
  },
  {
    name: "Material UI",
    icon: MUI,
  },
  {
    name: "BootStrap",
    icon: BootStrap,
  },
  {
    name: "SASS",
    icon: SASS,
  },
  {
    name: "CSS",
    icon: CSS,
  },
  {
    name: "HTML",
    icon: HTML,
  },
];
