import { faEnvelope, faLocationDot, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {SecTitle} from '..';
import './contactus.scss';

const contacts = [
    {
        id: 1,
        icon: faLocationDot,
        iconClass: 'location',
        title: 'Location',
        text1: 'Fayoum City, Egypt',
        type: 'location',
    },
    {
        id: 2,
        icon: faPhone,
        iconClass: 'phone',
        title: 'Contact Me',
        text1: '+2 01018674965',
        text2: '+2 01118822920',
        type: 'tel',
    },
    {
        id: 3,
        icon: faEnvelope,
        iconClass: 'email',
        title: 'Email me',
        text1: 'me@ahmedkhald.com',
        text2: 'ahmd.khaldd@gmail.com',
        type: 'mail',
    },
];

const Contactus = () => {
    return (
        <div className='contactus'>
            <div className='contactContainer'>
                <SecTitle title="Contact me" />
                <div className='formContainer'>
                    <form className='contactForm'>
                        <input className='formInput' type='text' placeholder='First name' />
                        <input className='formInput' type='text' placeholder='Last name' />
                        <input className='formInput' type='text' placeholder='Email Address' />
                        <input className='formInput' type='text' placeholder='Subject' />
                        <textarea className='formTxtArea' placeholder='Message' />
                        <button className='formBtn' type='submit'>
                            <span>Submit</span>
                            {/* <span>Send</span> */}
                        </button>
                    </form>
                </div>
                <ul className='contactsList'>
                    {contacts?.map((item) => (
                        <li className='contactsListItem' key={item.id}>
                            <div className='contBox'>
                                <FontAwesomeIcon className={`contIcon ${item.iconClass}`} icon={item.icon} />
                                <h4 className='contTitle'>{item.title}</h4>
                                
                                {item.type === 'tel'
                                    ? <div className='contDetails'>
                                        <a href={`tel: ${item.text1}`}>{item.text1}</a>
                                        {item.text2 && <a href={`tel: ${item.text2}`}>{item.text2}</a>}
                                    </div>
                                    : item.type === 'mail'
                                    ?
                                    <div className='contDetails'>
                                        <a href={`mailto:${item.text1}`}>{item.text1}</a>
                                        {item.text2 && <a href={`mailto:${item.text2}`}>{item.text2}</a>}
                                    </div>
                                    :
                                    <div className='contDetails'>
                                        <span>{item.text1}</span>
                                        {item.text2 && <span>{item.text2}</span>}
                                    </div>
                                }
                                
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default Contactus;
