import React from 'react';
import { Link } from 'react-router-dom';
import './about.scss';

const About = () => {
    return (
        <div className='about'>
            <div className='abContainer'>
                <div className='aboutMe'>
                    <div className='abTxt'>
                        <span>Hello, </span>
                        <span>My name is</span>
                    </div>
                    <h1 className='name'>Ahmed Khaled</h1>
                    <div className='abDesc'>
                        <span>Front End Web Developer</span>
                    </div>
                    <div className='btns'>
                        <button className='downloadBtn'>
                            <a href={process.env.PUBLIC_URL + './docs/AhmedKhaledAbdAllahFrontenddeveloper.pdf'} download="AhmedKhaledAbdAllahFrontenddeveloper.pdf" target='_blank' rel="noreferrer">Download CV</a>
                            {/* <a href={process.env.PUBLIC_URL + '/public/docs/AhmedKhaledAbdAllahFrontenddeveloper.pdf'} download target='_blank' rel="noreferrer">Download CV</a> */}
                            {/* <a href={process.env.PUBLIC_URL + './docs/AhmedKhaledAbdAllah01018674965CV.docx'} download target='_blank' rel="noreferrer">Download CV</a> */}
                        </button>
                    </div>
                </div>
                <div className='abtImage'>
                    <img className='abtImg' src={process.env.PUBLIC_URL + '/imgs/web_developing.gif'} alt='' />
                </div>
            </div>
        </div>
    )
}

export default About;
