import { skillsList } from '../Projects/projectList';
import SecTitle from '../SecTitle/SecTitle';
import './skills.scss';



const Skills = () => {
  return (
    <div className='skills'>
      <div className='skillsContainer'>
        <SecTitle title='My Skills' />
        <div className='skillsList'>
          {skillsList?.map((skill) => (
            <div key={skill.name} className='skillItem'>
              <span>{skill.name}</span>
              <img className='skillIcon' src={skill.icon} alt={skill.name} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Skills;
