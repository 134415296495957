import React from 'react';
import { BrowserRouter, Switch, Route, Link, Routes, Navigate } from "react-router-dom";
import { Home } from './pages';

const App = () => {
  return (
    <main className='app'>
      <BrowserRouter>
        <Routes>
          <Route path='/'>
            <Route index element={<Home />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </main>
  )
}

export default App;
