import { faBars, faBarsStaggered, faX, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { About, Contactus, Footer, Projects, Qr, Sidebar, Skills } from '../../components';
import './home.scss';

const Home = () => {
    const [isSideHidden, setIsSideHidden] = useState(true);

    const hideSidebarHandler = () => {
        setIsSideHidden(!isSideHidden);
    };

    return (
        <div className='home'>
            <div className='barsBox' onClick={hideSidebarHandler}>
                {isSideHidden ? <FontAwesomeIcon icon={faBars} /> : <FontAwesomeIcon icon={faX} />}
            </div>
            <div className={isSideHidden ? 'sidebarWrapper sidebarHidden' : 'sidebarWrapper'}>
                <Sidebar />
            </div>
            {/* {!isSideHidden && <Sidebar />} */}
            <div className={isSideHidden ? 'homeContainer hideSidebar' : 'homeContainer'}>
                <About id='#about' />
                <Skills id='skills' />
                <Projects id='#portfolio'  />
                <Contactus id='#contact'  />
                <Footer />
            </div>
            {isSideHidden && (
                <div className='qrWrapper'>
                    {/* <Qr /> */}
                </div>
            )
            }
        </div>
    )
}

export default Home;
