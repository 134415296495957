import React from 'react';
import './secTitle.scss';

const SecTitle = ({title}) => {
    return (
        <div className='titles'>
            {/* <p className='smTitle'>Contact Us</p> */}
            <h1 className='title'>{title}</h1>
        </div>
    )
}

export default SecTitle;
