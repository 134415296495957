import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Summarize, BusinessCenter, PhoneInTalk, LightMode, DarkMode } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faGithub, faLinkedinIn, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Qr } from "..";
import './sidebar.scss';


const Sidebar = () => {
    const [isDark, setIsDark] = useState(false);

    const handlePageMode = () => {
        setIsDark(!isDark);
    }

    return (
        <div className='sidebar'>
            <div className='sideImg'>
                <a href='/'>
                    <img
                        src={process.env.PUBLIC_URL + `/imgs/account.jpg`}
                        alt='Ahmed' 
                        className='img'
                    />
                </a>
            </div>
            <ul className='sideList'>
                <li className='listItem about'>
                    <a href='#about'>
                        <Summarize className='icon' />
                        <p className='itemTxt'>About</p>
                    </a>
                </li>
                <li className='listItem portfolio'>
                    <a href='#portfolio'>
                        <BusinessCenter className='icon' />
                        <p className='itemTxt'>Portfolio</p>
                    </a>
                </li>
                <li className='listItem contact'>
                    <a href='#contact'>
                        <PhoneInTalk className='icon' />
                        <p className='itemTxt'>Contact</p>
                    </a>
                </li>
            </ul>
            <div className='sideSocial'>
                <h4 className='sideTitle'>Follow me</h4>
                <ul className='contactIcons'>
                    <li className='contactIconItem github'>
                        <a href='https://github.com/Ahmd-Khaled' target="_blank" rel="noreferrer">
                            <FontAwesomeIcon className='icon1' icon={faGithub} />
                            <FontAwesomeIcon className='icon2' icon={faGithub} />
                        </a>
                    </li>
                    <li className='contactIconItem linked'>
                        <a href='https://www.linkedin.com/' target="_blank" rel="noreferrer">
                            <FontAwesomeIcon className='icon1' icon={faLinkedinIn} />
                            <FontAwesomeIcon className='icon2' icon={faLinkedinIn} />
                        </a>
                    </li>
                    <li className='contactIconItem whats'>
                        <a href="tel:+20 01018674965" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon className='icon1' icon={faWhatsapp} />
                            <FontAwesomeIcon className='icon2' icon={faWhatsapp} />
                        </a>
                    </li>
                    <li className='contactIconItem facebook'>
                        <a href='https://www.facebook.com' target="_blank" rel="noreferrer">
                            <FontAwesomeIcon className='icon1' icon={faFacebookF} />
                            <FontAwesomeIcon className='icon2' icon={faFacebookF} />
                        </a>
                    </li>
                    <li className='contactIconItem twitter'>
                        <a href='https://twitter.com/' target="_blank" rel="noreferrer">
                            <FontAwesomeIcon className='icon1' icon={faTwitter} />
                            <FontAwesomeIcon className='icon2' icon={faTwitter} />
                        </a>
                    </li>
                </ul>
            </div>
            {/* <Qr /> */}
            {/* <div className='pageMode' onClick={handlePageMode}>
                {isDark ? <LightMode className='icon' /> : <DarkMode className='icon' />}
            </div> */}
        </div>
    )
}

export default Sidebar;
